<template>
    <div class="modal" :class="{'is-active':show}" @keyup="onEscapeKeyUp">
        <div class="modal-background"></div>
        <div class="modal-context-width">
            <div class="container has-text-centered is-align-self-center ">
                <video controls autoplay muted class="is-fullwidth " :class="{'video-desktop':!isMobile, 'video-mobil':isMobile}">
                    <source id="rotative"  :src="storage + path" type="video/mp4">
                </video>
            </div>
        </div>
        <button class="modal-close is-large" aria-label="close" @click.prevent="$emit('close')"></button>
        
    </div>
</template>

<script>
export default {
    name: 'Modal-image-full',
    props:{
        storage: String,
        path: String,
        show: Boolean,
        isMobile: Boolean,
    },
    emits: ['close'],

    created() {
        window.addEventListener('keyup', this.onEscapeKeyUp)
    },
    
    methods: {
        onEscapeKeyUp(event) {
            if (event.key == 'Escape') {
                this.$emit('close')
            }
        }
    },

}
</script>

<style scoped>
.video-desktop{
    max-height: 690px;
    min-width: 500px;
}

.video-mobil{
    min-width: 400px;
}

</style>