<template>
    <div class="container">
        <div class="column">
            <div class="columns">
                 <div v-show="!isMobile" class="columns  pt-3">
                    <div class="column  mr-0 pr-0">
                           <button class="button is-static has-text-black" >Mostrar</button>
                    </div>
                    <div class="column  pl-0 control" v-tooltip="'Filas'">
                        <div class="select ">
                            <select class="options" v-model="mostrar_cantidad">
                                <option value="50" >50</option>
                                <option value="100">100</option>
                                <option value="200">200</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div v-show="isMobile" class="columns has-text-right is-mobile pt-3">
                    <div class="column  mr-0 pr-0 has-text-right">
                           <button class="button is-static has-text-black">Mostrar</button>
                    </div>
                    <div class="column  has-text-left control">
                            <div class="select ">
                                <select class="options" v-model="mostrar_cantidad">
                                    <option value="50" >50</option>
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="500">500</option>
                                </select>
                            </div>
                    </div>
                </div>
                 <div v-show="!isMobile"  class="column  has-text-center is-4">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left">
                            <input class="input " type="text" placeholder="Buscar anuncio" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div v-show="isMobile"  class="column  has-text-center ml-3">
                    <!-- Buscador -->
                    <div class=" field ">
                        <p class="control has-icons-left w-100" >
                            <input class="input " type="text" placeholder="Buscar anuncio" v-model="buscar">
                            <span class="icon is-left">
                                <i class="fas fa-search" ></i>
                            </span>
                        </p>
                    </div>
                </div>
                <div class="column">
                    <div class="buttons is-justify-content-flex-end">
                        <div class="dropdown pr-2" :class="{'is-active':mas_opciones}" >
                            <div id="mas-opciones" class="dropdown-trigger">
                                <button id="mas-opciones" class="button has-background-info has-text-white" aria-haspopup="true" aria-controls="dropdown-menu" @click="mas_opciones = !mas_opciones">
                                    <span id="mas-opciones">Mas opciones</span>
                                    <span id="mas-opciones" class="icon is-small">
                                        <i id="mas-opciones" class="fas fa-angle-down" aria-hidden="true"></i>
                                    </span>
                                </button>
                            </div>
                            <div class="dropdown-menu pt-0" id="dropdown-menu" role="menu">
                                <div class="dropdown-content">
                                    <a id="mas-opciones" class="dropdown-item" @click="eliminar_adv_masivo ">
                                        Eliminación masiva
                                    </a>
                                </div>
                            </div>
                        </div>

                        <button class="button has-background-info has-text-white" @click="filtro">Filtro</button>

                        <div>
                            <div class="modal" :class="{'is-active': filtrar}">
                                <div id="modal-background" class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content">
                                    <div class="container has-text-centered has-background-white p-3 px-5" id="modal">
                                        <div class="columns">
                                            <div class="column is-9 pb-0" :class="{'ml-5':!isMobile}">
                                                <h1 class="is-size-3 has-text-black has-text-weight-bold pt-3">Filtrar tabla</h1>
                                            </div>
                                            <div class="column" :class="{'mt-3 p-0 pt-2':!isMobile}">
                                                <button class="button verde-puwic mb-0 has-text-white has-text-weight-bold" @click="limpiarFiltro">Limpiar</button>
                                            </div>
                                        </div>
                                        <div :class="{'modal-scrolleable' : isMobile}">
                                            <!-- ID -->
                                            <div class="field columns mt-2" :class="{'mb-0' : isMobile}">
                                                <div class="column is-one-fifth ml-4 p-0 pr-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">ID</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.id" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.id" name="id" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Nombre -->
                                            <div class="field columns" :class="{'mb-0 pt-1' : isMobile, 'mb-0': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2">
                                                    <label class="label" :class="{'has-text-right': !isMobile}" >Nombre del anuncio</label>
                                                </div>
                                                <div class="is-align-self-center columns has-text-centered ml-0 mr-0 w-100">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1' : isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.nombre">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1' : isMobile}">
                                                        <input class="input" v-model="datos_filtro.nombre" name="nombre" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Anunciante -->
                                            <div class="field columns mt-0" :class="{'mb-0': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Anunciante</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.anunciante">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.anunciante" name="anunciante" type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Estado -->
                                            <div class="field columns" :class="{'my-0': isMobile, 'mb-0 mt-2': !isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Estado</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100" >
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.estado" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="no es">no es</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column " :class="{'py-1': isMobile}">
                                                        <div class="select w-100" >
                                                            <!-- style="width: 16.8rem" -->
                                                            <select class="options w-100" v-model="datos_filtro.estado" >
                                                                <option value="">Seleccione estado</option>
                                                                <option value="HABILITADO">Habilitado</option>
                                                                <option value="DESHABILITADO">Deshabilitado</option>
                                                                <option value="TERMINADO">Terminado</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Duracion -->
                                            <!-- <div class="field columns mt-0 mb-0" :class="{'mb-0 mt-2': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Duración</label>
                                                </div>
                                                <div v-show="!isMobile" class="columns has-text-centered mx-0 mb-1 w-100">
                                                    <div class="control column is-narrow">
                                                        <input class="input" style="width: 8.8rem" v-model="datos_filtro.fecha_inicio" name="fecha_inicio" type="text" placeholder="dd/mm/YY">
                                                    </div>

                                                    <div class="control column" >
                                                        <input class="input" v-model="datos_filtro.fecha_final" name="fecha_final" type="text" placeholder="dd/mm/YY">
                                                    </div>

                                                </div>
                                                <div v-show="isMobile" class="columns has-text-centered mx-0">
                                                    <div class="control column pt-1">
                                                        <input class="input" v-model="datos_filtro.fecha_inicio" name="fecha_inicio" type="text" placeholder="dd/mm/YY">
                                                    </div>

                                                    <div class="control column pt-1" style="min-width: 290px">
                                                        <input class="input" v-model="datos_filtro.fecha_final" name="fecha_final" type="text" placeholder="dd/mm/YY">
                                                    </div>

                                                </div>
                                            </div> -->


                                            <div class="field columns mt-0 mb-0" :class="{'mb-0 mt-2': isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2 is-align-self-center" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Duración</label>
                                                </div>
                                                <div class="column">
                                                    <div class="columns">
                                                        <div class="column is-flex-grow-0" >
                                                            <label class="label mt-2" style="width: 139px; margin: auto">Fecha incio</label>
                                                        </div>
                                                        <div class="control column ">

                                                            <input type="date" class="input input2" v-model="datos_filtro.fecha_inicio" />

                                                        </div>
                                                    </div>

                                                    <div class="columns">
                                                        <div class="column is-flex-grow-0 " >
                                                            <label class="label mt-2" style="width: 139px; margin: auto">Fecha final</label>
                                                        </div>

                                                        <div class="control column ">

                                                            <input type="date" class="input input2" v-model="datos_filtro.fecha_final" />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Costo Total -->
                                            <div class="field columns mt-0" :class="{'mb-0':isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Costo total</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" style="width: 139px">
                                                            <select class="options" v-model="tipo_filtro.costototal" style="width: 139px">
                                                                <option value="es">es</option>
                                                                <option value="mayor">mayor a</option>
                                                                <option value="menor">menor a</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.costototal" name="costototal" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Comentario -->
                                            <div class="field columns" :class="{'mb-0 mt-0': isMobile, 'mt-4':!isMobile}">
                                                <div class="column is-one-fifth ml-4 pt-2" style="min-width: 50px">
                                                    <label class="label" :class="{'has-text-right': !isMobile}">Comentario</label>
                                                </div>
                                                <div class="columns has-text-centered ml-0 mr-0 w-100">
                                                    <div class="control column is-flex-grow-0" :class="{'py-1': isMobile}">
                                                        <div class="select" >
                                                            <select class="options" v-model="tipo_filtro.comentario">
                                                                <option value="contiene">contiene</option>
                                                                <option value="no contiene">no contiene</option>
                                                                <option value="vacio">vacio</option>
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div class="control column" :class="{'pt-1': isMobile}">
                                                        <input class="input" v-model="datos_filtro.comentario" name="comentario" type="text">
                                                    </div>

                                                </div>
                                            </div>

                                            <!-- Botones -->
                                            <div class="columns">
                                                <div class="column">
                                                    <div class="buttons">
                                                        <div class="column is-4 px-1">
                                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="filtrar = false">Cancelar</button>
                                                        </div>
                                                        <div class="column pl-0">
                                                            <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" :class="{'ml-2':!isMobile}" style="width: 100%" @click="filtro_avanzado">Filtro</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <router-link :to="{name: 'NuevoAnuncio'}" class="button has-background-link has-text-white">Nuevo anuncio</router-link> -->
                        <a @click="verificarHabilitado('NuevoAnuncio')" class="button has-background-link has-text-white">Nuevo anuncio</a>
                    </div>
                </div>
            </div>
        </div>

        <button class="total-element">Total: {{Allanuncios.length}}</button>
        <button v-show="filtrado" class="total-element mx-0 pointer" @click="filtro">Total filtrado: {{totalElement}}</button>

        <div class="column table-container">
            <table class="table   has-background-white-ter" style="width: 100%">
                <thead>
                    <tr>
                    <th>
                        <div v-show="todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-check-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                        <div v-show="!todo">
                            <button class="button is-small is-light"  @click="marcar_todo">
                                <span class="icon-text">
                                    <span class="icon is-size-6">
                                        <i class="far fa-square"></i>
                                    </span>
                                </span>
                            </button>
                        </div>
                    </th>
                    <th>
                        <button @click="ordenar('id')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            ID
                            <div  v-show="clics.n1!=false">
                                <span  v-show="ordenarId!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarId==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('nombre')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Nombre del anuncio
                            <div  v-show="clics.n2!=false">
                                <span  v-show="ordenarNombre!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarNombre==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('anunciante')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Anunciante
                            <div  v-show="clics.n6!=false">
                                <span  v-show="ordenarAnunciante!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarAnunciante==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('estado')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Estado
                            <div  v-show="clics.n3!=false">
                                <span  v-show="ordenarEstado!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarEstado==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button  class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Duración
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('costo_total')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Costo total
                            <div  v-show="clics.n4!=false">
                                <span  v-show="ordenarCosto!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarCosto==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    <th>
                        <button @click="ordenar('comentario')" class="button  has-text-weight-medium has-text-black is-light px-0 py-0 ">
                            Comentario
                            <div  v-show="clics.n5!=false">
                                <span  v-show="ordenarComentario!=false">
                                    <i class="fas fa-arrow-up"></i>
                                </span>
                                <span v-show="ordenarComentario==false">
                                    <i class="fas fa-arrow-down"></i>
                                </span>
                            </div>
                        </button>
                    </th>
                    </tr>
                </thead>

                 <tbody >
                    <tr v-for="(anuncio,index) in  anuncios" :index="index" :key="anuncio.id" :class="{'fondo-fila':!anuncio.tildado && index%2==0,'seleccionado': anuncio.tildado, 'deseleccionado': !anuncio.tildado}">
                        <td>
                            <input v-if="!anuncio.tildado" @click="tildar(anuncio)" class="ml-2" type="checkbox">
                            <input v-else @click="tildar(anuncio)" class="ml-2" checked type="checkbox">
                        </td>
                        <td @click="modal(anuncio)">{{anuncio.id}}</td>
                        <td @click="modal(anuncio)">{{anuncio.nombre}}</td>
                        <td v-if="anuncio.anunciante!=null" @click="modal(anuncio)">{{anuncio.anunciante.nombre}} </td>
                        <td v-else @click="modal(anuncio)">{{anuncio.anunciante}} </td>
                        <td @click="modal(anuncio)">{{anuncio.estado.charAt(0)+anuncio.estado.slice(1).toLowerCase()}}</td>
                        <td @click="modal(anuncio)">{{(anuncio.duracion.fecha_inicio).split("/").reverse().join().replace(/[,]/gi,'/')}} - {{(anuncio.duracion.fecha_fin).split("/").reverse().join().replace(/[,]/gi,'/')}}</td>
                        <td @click="modal(anuncio)">${{anuncio.costo_total}}</td>
                        <td @click="modal(anuncio)">{{anuncio.comentario}}</td>

                        <!-- Modal principal -->
                        <div>
                            <div class="modal" :class="{'is-active': anuncio.activo}" @click="anuncio.activo = false">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" id="modal">
                                        <!-- <div class="column p-0 pt-4 px-4">
                                            <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Cargar pago</button>
                                        </div> -->
                                        <div class="column p-0 py-1 px-4">
                                            <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="verPuntoWifi(anuncio)">Ver punto WiFi</button>
                                        </div>
                                        <div class="column p-0 pb-1 px-4">
                                            <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="verImagen(anuncio)">Ver video</button>
                                        </div>
                                        <div class="column p-0 pb-1 px-4">
                                            <div v-show=" anuncio.estado == 'DESHABILITADO' ">
                                                  <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" @click="activar(anuncio) " style="width: 100%">Habilitar</button>
                                            </div>
                                             <div v-show=" anuncio.estado != 'DESHABILITADO' ">
                                                 <button class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" @click="activar(anuncio) " style="width: 100%">Deshabilitar</button>
                                            </div>
                                        </div>
                                        <div class="column p-0 pb-1 px-4">
                                            <!-- <router-link :to="{name: 'EditarAnuncio', params: {id: anuncio.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</router-link> -->
                                            <a @click="verificarHabilitadoParam('EditarAnuncio', anuncio)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 100%">Editar</a>
                                        </div>
                                        <div class="column p-0 pb-4 px-4">
                                            <button class="button has-background-danger is-outlined btn has-text-white has-text-weight-bold" style="width: 100%" @click="eliminar_adv(anuncio)">Eliminar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal ver Punto wifi -->
                        <div>
                            <div class="modal" :class="{'is-active': anuncio.ver_punto}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.1)"></div>
                                <div class="modal-content-width has-text-black" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">Puntos WiFi donde se</h1>
                                        <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}" style="margin-top: -10px">Muestra este anuncio</h1>
                                        <div class="my-5 ml-5 pl-5">
                                            <ul v-for="item in anuncio.puntowifis" :key="item.id" class="ml-4">
                                                <li class="has-text-weight-semibold has-text-left ml-5 pl-1 circulo">{{item.nombre}}</li>
                                            </ul>
                                        </div>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <div class="buttons">
                                                    <!-- <router-link :to="{name: 'EditarAnuncio', params: {id: anuncio.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 32%">Quiero editar</router-link> -->
                                                    <a @click="verificarHabilitadoParam('EditarAnuncio', anuncio)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 32%">Quiero editar</a>
                                                    <button class="button has-background-link is-outlined btn has-text-white has-text-weight-bold" style="width: 65%" @click="verPuntoWifi(anuncio)">Esta bien</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal de ver imagen -->
                        <div>
                            <div class="modal" :class="{'is-active': anuncio.ver_imagen}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.3)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-3':!isMobile}" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold mb-2">Imagen del anuncio</h1>
                                        <div :class="{'modal-scrolleable' : isMobile}">
                                            <!-- <img :src="anuncio.imagen" alt="" style="width: 250px"> -->
                                            <video autoplay muted width="200" height="90" @click="anuncio.full_image = true" style="cursor: pointer">
                                                <source id="rotative"  :src="url_storage + anuncio.imagen" type="video/mp4">
                                            </video>
                                        </div>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <div class="buttons">
                                                    <!-- <router-link :to="{name: 'EditarAnuncio', params: {id: anuncio.id}}" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 40%">Quiero editar</router-link> -->
                                                    <a @click="verificarHabilitadoParam('EditarAnuncio', anuncio)" class="button verde-puwic is-outlined btn has-text-white has-text-weight-bold" style="width: 40%">Quiero editar</a>
                                                    <button class="button has-background-link is-outlined btn has-text-white has-text-weight-bold" style="width: 57%" @click="verImagen(anuncio)">Esta bien</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Modal imagen completa -->
                            <ModalImageFull
                                :show = anuncio.full_image
                                :path = anuncio.imagen
                                :storage = url_storage
                                :isMobile = isMobile
                                @close="anuncio.full_image = false;"
                            ></ModalImageFull>


                        <!-- Modal de habilitar y deshabilitar -->
                        <div>
                            <div class="modal " :class="{'is-active': anuncio.deshabilitar}" >
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)" ></div>
                                <div class="modal-content-width ">
                                    <div class="column  is-full  has-text-centered box" id="modal" >
                                        <div class="column is-full has-text-left" style="background-color:#f8d7da">

                                            <div v-show=" anuncio.estado == 'DESHABILITADO' ">
                                                <h3 class="title is-5 " style="color:#721c24">¿Seguro quiere habilitar el anuncio {{anuncio.nombre}}?</h3>
                                            </div>
                                                <div v-show=" anuncio.estado != 'DESHABILITADO' ">
                                                <h3 class="title is-5 " style="color:#721c24">¿Seguro quiere deshabilitar el anuncio {{anuncio.nombre}}?</h3>
                                            </div>

                                        </div>
                                        <div class="column is-full has-text-left ">
                                            <div v-show=" anuncio.estado == 'DESHABILITADO' ">
                                                <p >Se habilitará el anuncio {{anuncio.nombre}}. Podra deshabilitarlo </p>
                                            </div>
                                                <div v-show=" anuncio.estado != 'DESHABILITADO' ">
                                                <p >Se deshabilitará el anuncio {{anuncio.nombre}}. Podra habilitarlo </p>
                                            </div>
                                        </div>
                                        <div class="column is-full  has-text-centered  ">
                                            <!-- APP DESKTOP -->
                                            <div  v-show=" anuncio.estado == 'DESHABILITADO' " class="columns">
                                                <div v-show="!isMobile" class="column  has-text-left  ">
                                                    <button v-show="!cargando" class="button is-danger has-text-white" @click="cambiar_estado(anuncio)">Si,habilitar</button>
                                                    <button v-show="cargando" class="button is-danger has-text-white is-loading" style="width: 100%"></button>
                                                </div>
                                                <div v-show="!isMobile" class="column  has-text-right ">
                                                    <button class="  button verde-puwic has-text-white  px-6" @click="noDeshabilitar(anuncio)">No,quiero habilitar</button>
                                                </div>
                                            </div>
                                                <div  v-show=" anuncio.estado != 'DESHABILITADO' " class="columns">
                                                <div v-show="!isMobile" class="column  has-text-left  ">
                                                    <button v-show="!cargando" class="  button is-danger has-text-white  " @click="cambiar_estado(anuncio)">Si,deshabilitar</button>
                                                    <button v-show="cargando" class="button is-danger has-text-white is-loading" style="width: 100%"></button>
                                                </div>
                                                <div v-show="!isMobile" class="column  has-text-right ">
                                                    <button class="  button verde-puwic has-text-white  px-6" @click="noDeshabilitar(anuncio)">No quiero deshabilitar</button>
                                                </div>
                                            </div>
                                            <!-- APP MOVIL -->
                                            <div  v-show=" anuncio.estado == 'DESHABILITADO' " class="columns ">
                                                <div v-show="isMobile" class="column  has-text-center  ">
                                                    <button v-show="!cargando" class="button is-danger has-text-white" @click="cambiar_estado(anuncio)">Si,habilitar</button>
                                                    <button v-show="cargando" class="button is-danger has-text-white is-loading" style="width: 100%"></button>
                                                </div>
                                                <div v-show="isMobile" class="column  has-text-center ">
                                                    <button class=" button verde-puwic has-text-white  px-6" @click="noDeshabilitar(anuncio)">No,quiero habilitar</button>
                                                </div>
                                            </div>

                                            <div  v-show=" anuncio.estado != 'DESHABILITADO' " class="columns ">
                                                <div v-show="isMobile" class="column  has-text-center  ">
                                                    <button v-show="!cargando" class="button is-danger has-text-white  " @click="cambiar_estado(anuncio)">Si,deshabilitar</button>
                                                    <button v-show="cargando" class="button is-danger has-text-white is-loading"></button>
                                                </div>
                                                <div v-show="isMobile" class="column  has-text-center ">
                                                    <button class=" button verde-puwic has-text-white  px-6" @click="noDeshabilitar(anuncio)">No quiero deshabilitar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- modal de advertencia -->
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia}" >
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width" style="border:1px ridge grey;">
                                    <div class="container has-text-centered has-background-white px-1 py-1" style="width: 320px" id="modal">
                                        <h1 class="is-size-3 has-text-weight-semibold has-text-danger" :class="{'is-size-4':isMobile}">¿Estas seguro?</h1>

                                        <p v-show="opsMasivo==false" class="has-text-centered ">Quiere eliminar el anuncio {{anuncio_elegido.nombre}}. Esta acción no se podra revertir</p>
                                        <p v-show="opsMasivo==true" class="has-text-centered ">Se van eliminar los anuncios seleccionados. Esta accion no se puede revertir.</p>
                                        <div class="columns   py-1 pt-4 px-1">
                                            <div class="column ">
                                                <button class="button w-100 has-background-danger is-outline btn has-text-white has-text-weight-blod" @click="cancelar">Cancelar</button>
                                            </div>
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="confirmar">Si,eliminar</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': mostrar_advertencia2}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">No se puede editar el anuncio porque esta terminado</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_advertencia2= false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="modal" :class="{'is-active': mostrar_validacion}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p class="has-text-centered has-text-danger">Se debe seleccionar algun anuncio para realizar la accion</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="mostrar_validacion = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Mensaje de que se cargo con exito -->
                        <div>
                            <div class="modal" :class="{'is-active': carga_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p  v-show="comprobar==true" class="has-text-centered has-text-success">Se cargo con exito el anuncio.</p>
                                        <p  v-show="comprobar_edi==true" class="has-text-centered has-text-success">Se edito con exito el anuncio.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="carga_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div>
                            <div class="modal" :class="{'is-active': accion_exitosa}">
                                <div class="modal-background " style="background-color: rgb(197, 197, 197, 0.0)"></div>
                                <div class="modal-content-width has-text-black" style="border:1px ridge grey;" :class="{'modal-puntowifi-escritorio' : !isMobile, 'modal-puntowifi-mobil' : isMobile}">
                                    <div class="container has-text-centered has-background-white" :class="{'p-2':isMobile, 'p-5':!isMobile}" id="modal">
                                        <!-- <h1 class="is-size-3 has-text-weight-semibold" :class="{'is-size-4':isMobile}">No se puede borrar el anuncio</h1> -->
                                        <p v-show="paso_elim==true" class="has-text-centered has-text-success">Se elimino los anuncios seleccionados exitosamente.</p>
                                        <p v-show="paso_elim==false" class="has-text-centered has-text-danger">No se logro eliminar a los anuncios seleccionados.</p>
                                        <div class="columns mt-2">
                                            <div class="column">
                                                <button class="button w-100 verde-puwic is-outline btn has-text-white has-text-weight-blod" @click="accion_exitosa = false">Esta bien</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>

            <div v-show="preload" class="loader-wrapper is-active-loading">
                <div class="loader is-loading "></div>
            </div>

            <div v-if="!preload && anuncios.length == 0">
                <h1 class="has-text-centered is-size-4 has-text-grey">No hay anuncios</h1>
            </div>
            <nav v-show="totalElement > mostrar_cantidad" class="pagination is-right" role="navigation" aria-label="pagination">
                <button v-if="paginaInfo.page > 1" class="pagination-previous" @click="prevPage">Anterior</button>
                <button v-else class="pagination-previous" disabled >Anterior</button>
                <button v-if="paginaInfo.page < pageCont" class="pagination-next" @click="nextPage">Siguiente</button>
                <button v-else class="pagination-next" disabled>Next</button>
                <ul class="pagination-list" style="justify-content: center">
                    <li v-show="paginaInfo.prev > 1"> <button class="pagination-link" @click="inicio">1</button></li>
                    <li v-show="paginaInfo.prev > 2"><span class="pagination-ellipsis">&hellip;</span></li>
                    <li v-show="paginaInfo.prev != null"><button class="pagination-link" @click="prevPage">{{paginaInfo.prev}}</button></li>
                    <li><button class="pagination-link is-current">{{paginaInfo.page}}</button></li>
                    <li v-show="paginaInfo.next != null && paginaInfo.next > 1"><button class="pagination-link" @click="nextPage">{{paginaInfo.next}}</button></li>
                    <li v-show="paginaInfo.next < pageCont - 1 && paginaInfo.next != null"><span class="pagination-ellipsis">&hellip;</span></li>
                    <li v-show="paginaInfo.next != null && (paginaInfo.next < pageCont) && (pageCont != 1)" ><button class="pagination-link" @click="final">{{pageCont}}</button></li>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import {filtrar_campo_numeral} from '@/composables/filtrar_campo_numeral.js'
import {filtrar_campo_texto} from '@/composables/filtrar_campo_texto.js'
import {ordenar_arreglo} from '@/composables/ordenar_arreglo.js'
import store from '@/store';
import {GraphQLClient, request as fetchGQL} from 'graphql-request';
import { inject, ref, watch, watchEffect} from 'vue'
import { useRoute, useRouter } from 'vue-router';
import {queryMe} from '@/composables/queryMe.js';
// import {ModalImageFull} from '@components/Modal-image-full.vue'
import ModalImageFull from '../../components/Modal-image-full'


export default {

    components: {
        ModalImageFull,
    },

    setup(){
        const mostrar_cantidad = ref(50)
        const todo = ref(false)
        const isMobile = inject('isMobile')
        const mostrar = ref(false)
        const anuncios= ref([])
        const anuncios_aux = ref([])
        const filtrar = ref(false)
        const datos_filtro = ref({id: '', nombre: '', anunciante: '', estado: '', fecha_inicio: '', fecha_final: '', costototal: '', comentario: ''})
        const tipo_filtro = ref({id: 'es', nombre: 'contiene', anunciante: 'contiene', estado: 'es', fecha_inicio: '', fecha_final: '', costototal: 'es', comentario: 'contiene'})
        const buscar = ref('')
        const info = ref({count: 0, pages:0, next:0, prev: 0, page: 0, total: 0})
        const route = useRoute()
        const router = useRouter()
        const endpoint = store.state.url_backend
        const clics = ref({n1:false,n2:false,n3:false, n4:false,n5:false,n6:false})
        const ordenarId = ref(false)
        const ordenarNombre = ref(false)
        const ordenarEstado = ref(false)
        const ordenarCosto = ref(false)
        const ordenarComentario = ref(false)
        const ordenarAnunciante = ref(false)
        const mas_opciones = ref(false)
        const eliminarAnuncios = ref([])
        const Allanuncios = ref([])
        const paginaInfo = ref({page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0})
        const pageCont = ref(0) // capaz tenga que agregarlo en la consulta de datos
        const totalElement = ref(0)
        const url_storage = store.state.url_storage
        const anuncio_elegido = ref([{id:null,nombre:''}])
        const mostrar_advertencia = ref(false)
        const mostrar_advertencia2 = ref(false)
        const opsMasivo = ref(false)
        const mostrar_validacion = ref(false)
        const cant_elegida = ref(null)
        const cargando = ref(false)
        const filtrado = ref(false)
        const preload = ref(true)
        const carga_exitosa = ref(false)
        const comprobar = store.state.carga_exitosa_ciudad
        const comprobar_edi = store.state.edicion_exitosa
        const accion_exitosa = ref(false)
        const paso_elim = ref(false)


        const comprobar_carga = () => {

            // console.log(comprobar)
            if(comprobar==true){
               carga_exitosa.value = true
               let accion = "nuevoanuncio"
               store.commit('verificar_carga',accion)
            }

        }
        const comprobar_edicion = () => {

            // console.log(comprobar)
            if(comprobar_edi==true){
               carga_exitosa.value = true
               let accion = "edicionanuncio"
               store.commit('verificar_carga',accion)
            }

        }

        const eliminar_adv = (anuncio) => {
                    opsMasivo.value = false
                    mostrar_advertencia.value = !mostrar_advertencia.value
                    anuncio_elegido.value.id = anuncio.id
                    anuncio_elegido.value.nombre = anuncio.nombre
                    // console.log(ciudadelegida.value.id)
                    // console.log(ciudadelegida.value.nombre)

        }
        const eliminar_adv_masivo = () => {

            let cont = 0
            // console.log(anuncios.value)
            anuncios.value.forEach(element => {
                if(element.tildado){
                        cont += 1
                }
            })
            // console.log("cantidad seleccioanda",cont)
            if(cont!=0){
                mostrar_validacion.value = false
                opsMasivo.value =  true
                eliminarAnuncios.value = []
                mostrar_advertencia.value = !mostrar_advertencia.value
            }else{
                mostrar_validacion.value = true
            }
        // console.log(eliminarCiudades.value.length)
        }


        const confirmar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value
            if(opsMasivo.value==true){
                eliminar_masivamente()
                // console.log(eliminarAnuncios.value)
            }else{
                 eliminar(anuncio_elegido.value.id)
            }
        }

        const cancelar = () => {
            mostrar_advertencia.value = !mostrar_advertencia.value
        }

        const eliminar_masivamente = async () => {
            anuncios.value.forEach(element => {
                if (element.tildado == true) {
                    eliminarAnuncios.value.push({id: element.id})
                }
            })
            // console.log("cantidad de anuncios que ddeben ser eliminados: ",eliminarAnuncios.value)
            eliminarAnuncios.value.forEach(element => {
                eliminar(element.id)
            })
        }


        const verificarHabilitado = async (name) => {
            let habilitado = await queryMe()
            // console.log(name)
            if (habilitado) {
                router.push({name: name})
            } else {
                router.push({name: 'Login'})
            }
        }

        const verificarHabilitadoParam = async (name, params) => {
            let habilitado = await queryMe()

            // console.log(name)
            // console.log(params.estado)
            if (habilitado) {
                if(params.estado !='TERMINADO'){
                    router.push({name: name, params: {id: params.id} })
                }else{
                    mostrar_advertencia2.value = !mostrar_advertencia2.value
                }

            } else {
                router.push({name: 'Login'})
            }
        }

        watchEffect(() => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            client.rawRequest(/* GraphQL */ `
            query($first:Int!){
                anuncios(first: $first){
                    paginatorInfo{
                        count
                        currentPage
                        hasMorePages
                        lastPage
                        total
                    }
                    data{
                        id,
                        nombre,
                        estado,
                        duracion,
                        costototal,
                        comentario,
                        anunciante{
                            id,
                            nombre
                        }
                        puntowifis{
                            id
                            nombre
                        },
                        path_img
                    }
                }
            }`,
            {
                first: 99999
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then(async data => {
                // console.log(data)

                let anuncios_traido = data.data.anuncios.data

                await anuncios_traido.forEach(element => {
                    //console.log(JSON.parse(element.duracion)) convierte un json a object
                    Allanuncios.value.push({anunciante: element.anunciante ? element.anunciante : {nombre:''}, id: element.id, nombre: element.nombre,estado: element.estado, duracion: JSON.parse(element.duracion), costo_total: element.costototal, comentario: element.comentario, tildado: false, activo: false, puntowifis: element.puntowifis, imagen: element.path_img, ver_punto: false,deshabilitar: false, ver_imagen: false, full_image: false})
                })

                pageCont.value = Math.ceil(Allanuncios.value.length/paginaInfo.value.pageSize)

                if (pageCont.value > 1) {
                    paginaInfo.value.next = paginaInfo.value.page + 1
                }
                // console.log(Allanuncios.value)

                anuncios_aux.value = Allanuncios.value
                showImpresiones(anuncios_aux.value)
                preload.value = false
            })
            .catch(error => {
                // console.log(error)
            })
        })


        const paginate = (array, page_size, page_number) => {
            return array.slice((page_number - 1) * page_size, page_number * page_size)
        }

        const nextPage = () => {
            paginaInfo.value.page++
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
                paginaInfo.value.prev = paginaInfo.value.page - 1
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(anuncios_aux.value)
        }

        const prevPage = () => {
            paginaInfo.value.page--
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
                paginaInfo.value.next = paginaInfo.value.page + 1
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(anuncios_aux.value)
        }

        const inicio = () => {
            paginaInfo.value.page = 1
            paginaInfo.value.prev = null
            if (paginaInfo.value.page >= pageCont.value) {
                paginaInfo.value.next = null
            } else {
                paginaInfo.value.next = paginaInfo.value.page + 1
            }
            showImpresiones(anuncios_aux.value)
        }

        const final = () => {
            paginaInfo.value.page = pageCont.value
            paginaInfo.value.next = null
            if (paginaInfo.value.page <= 1) {
                paginaInfo.value.prev = null
            } else {
                paginaInfo.value.prev = paginaInfo.value.page - 1
            }
            showImpresiones(anuncios_aux.value)
        }

        const showImpresiones = (array) => {
            anuncios.value  = paginate(array, paginaInfo.value.pageSize, paginaInfo.value.page)
        }

        watch(mostrar_cantidad, () => {
            paginaInfo.value = {page: 1, pageSize: parseInt(mostrar_cantidad.value), prev: null, next: 0}
            // console.log(paginaInfo.value.pageSize)
            pageCont.value = Math.ceil(anuncios_aux.value.length/paginaInfo.value.pageSize)
            // console.log(pageCont.value)
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(anuncios_aux.value)
        })

        watch(buscar, () => {
            filtro_buscador()
        })

        const filtro_buscador = () => {
            let arreglo_aux = []
            anuncios_aux.value = Allanuncios.value
            if (buscar.value != '') {
                filtrado.value = true
                anuncios_aux.value.filter((anuncio)=>{
                    let dato =  anuncio.id.match(buscar.value)
                        || anuncio.costo_total.toString().match(buscar.value)
                        || anuncio.nombre.toLowerCase().match(buscar.value.toLowerCase())
                        || anuncio.comentario.toLowerCase().match(buscar.value.toLowerCase())
                        || anuncio.anunciante.nombre.toLowerCase().match(buscar.value.toLowerCase())
                        || JSON.stringify(anuncio.duracion).match(buscar.value)
                        || anuncio.estado.toString().toLowerCase().match(buscar.value.toLowerCase())

                    if (dato != null) {
                        arreglo_aux.push(anuncio)
                    }

                })
                anuncios_aux.value = arreglo_aux
            } else {
                filtrado.value = false
                anuncios_aux.value = Allanuncios.value
            }
            pageCont.value = Math.ceil(anuncios_aux.value.length/paginaInfo.value.pageSize)
            totalElement.value = anuncios_aux.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            showImpresiones(anuncios_aux.value)
        }

        const inicializar_clics = () => {
            clics.value.n1 = false
            clics.value.n2 = false
            clics.value.n3 = false
            clics.value.n4 = false
            clics.value.n5 = false
            clics.value.n6 = false
        }

        const ordenar = (tipo) => {
            // console.log(anuncios_aux.value)
            inicializar_clics()
            switch (tipo) {
                case "id":
                    clics.value.n1 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarId.value, anuncios_aux.value)
                    ordenarId.value = !ordenarId.value
                    break;

                case "nombre":
                    clics.value.n2 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarNombre.value, anuncios_aux.value)
                    ordenarNombre.value = !ordenarNombre.value
                    break;

                case "estado":
                    clics.value.n3 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarEstado.value, anuncios_aux.value)
                    ordenarEstado.value = !ordenarEstado.value
                    break;

                case "costo_total":
                    clics.value.n4 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarCosto.value, anuncios_aux.value)
                    ordenarCosto.value = !ordenarCosto.value
                    break;

                case "comentario":
                    clics.value.n5 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarComentario.value, anuncios_aux.value)
                    ordenarComentario.value = !ordenarComentario.value
                    break;

                case "anunciante":
                    clics.value.n6 = true
                    anuncios_aux.value = ordenar_arreglo(tipo, ordenarAnunciante.value, anuncios_aux.value)
                    ordenarAnunciante.value = !ordenarAnunciante.value
                    break;

                default:
                    break;
            }

            showImpresiones(anuncios_aux.value)

        }

        const cambiar_estado = async (anuncio) => {
            cargando.value =true
            let habilitado = await queryMe()
            if (habilitado) {
                let id = anuncio.id
                if(anuncio.estado == 'DESHABILITADO'){
                    anuncio.estado = 'HABILITADO'
                    habilitar(id, anuncio.estado)
                }else{
                    anuncio.estado = 'DESHABILITADO'
                    deshabilitar(id, anuncio.estado)
                }

                anuncio.deshabilitar = false
                cargando.value = false

            } else {
                router.push({name: 'Login'})

            }
        }

        const habilitar = (id, estado) => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            mutation($id:ID!, $estado:EstadoAnuncio!){
                habilitaAnuncio(id: $id, estado: $estado){
                    id
                    nombre
                    estado
                }
            }`,
            {
                id: id,
                estado: estado
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)
            })
            .catch(error => {
                // console.log(error.response)
            })

        }

        const deshabilitar = (id, estado) => {
            const client = new GraphQLClient(endpoint)
            client.rawRequest(/* GraphQL */ `
            mutation($id:ID!, $estado:EstadoAnuncio!){
                deshabilitaAnuncio(id: $id, estado: $estado){
                    id
                    nombre
                    estado
                }
            }`,
            {
                id: id,
                estado: estado
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then((data) => {
                // console.log(data)
            })
            .catch(error => {
                // console.log(error.response)
            })

        }

        const noDeshabilitar = (anuncio) => {
            anuncio.deshabilitar = false
        }

        const activar = (anuncio) => {

            anuncio.deshabilitar = true
            anuncio.activo = false

        }
        const verPuntoWifi = (anuncio) => {
            anuncio.ver_punto = !anuncio.ver_punto
        }

        const verImagen = (anuncio) => {
            anuncio.ver_imagen = !anuncio.ver_imagen
        }

        const modal = (item) => {
            item.activo = !item.activo
        }

        const tildar = (anuncio) => {
            anuncio.tildado = !anuncio.tildado


            // let found = anuncios_aux.value.find(element => element.id == anuncio.id);


            // anuncios_elegidos.value.push({ id: found})

            // console.log(anuncios_elegidos.value)

            // anuncios_elegidos.value = []

        }

        const desmarcar_todo = () => {
            anuncios.value.forEach(element => {
                element.tildado = false
            });
        }

        const marcar_todo = () => {
            todo.value = !todo.value
            if(todo.value){
                anuncios_aux.value.forEach(element => {
                    element.tildado = true
                })
            }else{
                anuncios_aux.value.forEach(element => {
                    element.tildado = false
                });
            }
            showImpresiones(anuncios_aux.value)
        }

        const filtro = () => {
            if (!filtrado.value) {
                limpiarFiltro()
            }
            filtrar.value = true
        }

        const limpiarFiltro = () => {
            datos_filtro.value = {id: '', nombre: '', anunciante: '', estado: '', fecha_inicio: '', fecha_final: '', costototal: '', comentario: ''}
            tipo_filtro.value = {id: 'es', nombre: 'contiene', anunciante: 'contiene', estado: 'es', fecha_inicio: '', fecha_final: '', costototal: 'es', comentario: 'contiene'}
            buscar.value = ''
        }

        const filtro_avanzado = () => {
            let aux = []
            // console.log(datos_filtro.value.fecha_inicio)
            // console.log(datos_filtro.value.fecha_final)
            let anun_aux = Allanuncios.value
            if (datos_filtro.value.id == '' && datos_filtro.value.nombre == '' && datos_filtro.value.anunciante == ''
            && datos_filtro.value.estado == '' && datos_filtro.value.fecha_inicio == '' && datos_filtro.value.fecha_final == ''
            && datos_filtro.value.costototal == '' && datos_filtro.value.comentario == '') {
                filtrado.value = false
            } else {
                filtrado.value = true
            }

            if (buscar.value != '') {
                filtro_buscador()
            }

            anuncios.value = anun_aux

            if (datos_filtro.value.id != '') {
                anuncios.value = filtrar_campo_numeral(datos_filtro.value.id, tipo_filtro.value.id, "id", anuncios.value, aux, "anuncios")
            }
            aux = []

            if (datos_filtro.value.nombre != '') {
                anuncios.value = filtrar_campo_texto(datos_filtro.value.nombre, tipo_filtro.value.nombre, "nombre", anuncios.value, aux, "anuncios")
            }
            aux = []


            if (datos_filtro.value.anunciante != '') {
                anuncios.value = filtrar_campo_texto(datos_filtro.value.anunciante, tipo_filtro.value.anunciante, "anunciante", anuncios.value, aux, "anuncios")
            }
            aux = []

            if (datos_filtro.value.costototal != '') {
                anuncios.value = filtrar_campo_numeral(datos_filtro.value.costototal, tipo_filtro.value.costototal, "costo total", anuncios.value, aux, "anuncios")
            }
            aux = []

            if (datos_filtro.value.comentario != '' || tipo_filtro.value.comentario == 'vacio') {
                anuncios.value = filtrar_campo_texto(datos_filtro.value.comentario, tipo_filtro.value.comentario, "comentario", anuncios.value, aux, "anuncios")
            }
            aux = []

            if (datos_filtro.value.estado != '') {
                anuncios.value = filtrar_campo_texto(datos_filtro.value.estado, tipo_filtro.value.estado, "estado", anuncios.value, aux, "anuncios")
            }
            aux = []

            if (datos_filtro.value.fecha_inicio != '') {
                let fecha_incio = datos_filtro.value.fecha_inicio.replace(/[-]/gi,'/')
                anuncios.value.forEach(element => {
                    if (fecha_incio <= element.duracion.fecha_inicio) {
                        aux.push({id: element.id, nombre: element.nombre, anunciante: element.anunciante, estado: element.estado, duracion: element.duracion, costo_total: element.costo_total, comentario: element.comentario, tildado: false, activo: false, puntowifis: element.puntowifis, imagen: element.imagen, ver_punto: false, ver_imagen: false, full_image: false})

                    }
                })
                anuncios.value = aux
            }
            aux = []

            if (datos_filtro.value.fecha_final != '') {
                let fecha_final = datos_filtro.value.fecha_final.replace(/[-]/gi,'/')
                anuncios.value.forEach(element => {
                    if (fecha_final >= element.duracion.fecha_fin) {

                        aux.push({id: element.id, nombre: element.nombre, anunciante: element.anunciante, estado: element.estado, duracion: element.duracion, costo_total: element.costo_total, comentario: element.comentario, tildado: false, activo: false, puntowifis: element.puntowifis, imagen: element.imagen, ver_punto: false, ver_imagen: false, full_image: false})

                    }

                })
                anuncios.value = aux
            }
            aux = []

            pageCont.value = Math.ceil(anuncios.value.length/paginaInfo.value.pageSize)
            totalElement.value = anuncios.value.length
            paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
            if (pageCont.value > 1) {
                paginaInfo.value.next = paginaInfo.value.page +1
            }
            anuncios_aux.value = anuncios.value

            showImpresiones(anuncios_aux.value)

            filtrar.value = false
        }

        const traerAnuncio = () => {
            const client = new GraphQLClient(endpoint) // creamos la consulta para usarlo luego

            watchEffect(() => {

                client.rawRequest(/* GraphQL */ `
                query($page: Int,$first:Int!){
                    anuncios(first: $first, page: $page){
                        paginatorInfo{
                            count
                            currentPage
                            hasMorePages
                            lastPage
                            total
                        }
                        data{
                            id,
                            nombre,
                            estado,
                            duracion,
                            costototal,
                            comentario,
                            anunciante{
                                id,
                                nombre
                            }
                            puntowifis{
                                id
                                nombre
                            },
                            path_img
                        }
                    }
                }`,
                {
                    page: parseInt(route.params.page),
                    first: mostrar_cantidad.value

                },
                {
                    authorization: `Bearer ${ localStorage.getItem('user_token') }`
                })
                .then(data => {
                    // console.log(data)
                    anuncios.value = []
                    totalElement.value = data.data.anuncios.paginatorInfo.total

                    let anuncios_traido = data.data.anuncios.data

                    anuncios_traido.forEach(element => {
                        //console.log(JSON.parse(element.duracion)) convierte un json a object
                        anuncios.value.push({anunciante:element.anunciante, id: element.id, nombre: element.nombre,estado: element.estado, duracion: JSON.parse(element.duracion), costo_total: element.costototal, comentario: element.comentario, tildado: false, activo: false, puntowifis: element.puntowifis, imagen: element.path_img, ver_punto: false,deshabilitar: false, ver_imagen: false, full_image: false})
                    })

                })
                .catch(error => {
                    // console.log(error.response)
                })
            })
        }

        const eliminar = async (anuncio_id) => {
            let habilitado = await queryMe()
            if (habilitado) {
                eliminando(anuncio_id)
            } else {
                router.push({name: 'Login'})
            }
        }

        const eliminando = (anuncio_id) => {
            const client = new GraphQLClient(endpoint)

            client.rawRequest(/* GraphQL */ `
            mutation($id: ID!){
                eliminaAnuncio(id: $id){
                    id
                    nombre
                }
            }`,
            {
                id: anuncio_id
            },
            {
                authorization: `Bearer ${ localStorage.getItem('user_token') }`
            })
            .then(data => {
                // console.log(data)

                accion_exitosa.value = true
                paso_elim.value = true

                let cont = 0
                anuncios_aux.value.forEach(element => {
                    if (element.id == data.data.eliminaAnuncio.id) {
                        anuncios_aux.value.splice(cont,1)
                    }
                    cont += 1
                    pageCont.value = Math.ceil(anuncios_aux.value.length/paginaInfo.value.pageSize)
                    totalElement.value = anuncios_aux.value.length
                    paginaInfo.value = {page: 1, pageSize: mostrar_cantidad.value, prev: null, next: 0}
                    if (pageCont.value > 1) {
                        paginaInfo.value.next = paginaInfo.value.page +1
                    }
                    showImpresiones(anuncios_aux.value)
                })
            }).catch(error => {
                // let mensaje = error.message


                accion_exitosa.value = false
                paso_elim.value = false

                // console.log(error.response)

            })
        }

        document.addEventListener('click', function(e) {
            let clic = e.target.getAttribute("id")

            if (clic != "mas-opciones")  {
                mas_opciones.value = false
                if (clic == 'modal-background') {
                    filtrar.value = false
                }
            }
        }, false)


        return {

            mostrar_cantidad,
            todo ,
            isMobile,
            mostrar ,
            anuncios,
            anuncios_aux,
            filtrar ,
            datos_filtro,
            tipo_filtro ,
            buscar,
            info,
            mas_opciones ,
            eliminarAnuncios,
            eliminar_masivamente ,

            cambiar_estado,
            noDeshabilitar,
            activar,
            verPuntoWifi,
            verImagen,
            modal,
            tildar,
            desmarcar_todo,
            marcar_todo,
            filtro,
            limpiarFiltro,
            filtro_avanzado,
            traerAnuncio ,
            eliminar,
            clics,
            ordenarId ,
            ordenarNombre ,
            ordenarEstado ,
            ordenarCosto ,
            ordenarComentario ,
            ordenarAnunciante,

            nextPage,
            prevPage,
            inicio,
            final,
            paginaInfo,
            pageCont,
            totalElement,
            ordenar,
            url_storage,
            verificarHabilitado,
            verificarHabilitadoParam,
            cargando,
            anuncio_elegido ,
            mostrar_advertencia ,
            cant_elegida ,
            eliminar_adv,
            confirmar,
            cancelar,
            opsMasivo ,
            eliminar_adv_masivo ,
            mostrar_advertencia2 ,
            Allanuncios,
            filtrado,
            mostrar_validacion,
            preload,

            carga_exitosa,
            comprobar,
            comprobar_carga,
            comprobar_edicion,
            comprobar_edi,
            accion_exitosa,
            paso_elim,


        }

    },

    created(){
        this.traerAnuncio()
        this.anuncios_aux = this.anuncios
        this.comprobar_carga()
        this.comprobar_edicion()
    },


}
</script>

<style scoped>
.modal-puntowifi-escritorio{
    min-width: 440px
}

.modal-puntowifi-mobil{
    min-width: 355px
}

.circulo{
    list-style-type: disc;
}

.seleccionado{
    background-color: #01AC97;
}
.seleccionado:hover{
    background-color: #008575;
}

.deseleccionado:hover{
    background-color: #EDEDED;
}
.fondo-fila{
    background-color: rgb(223, 219, 219);
}
</style>